import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { useState } from "react";
import { IoMdEye } from "react-icons/io";

interface ViewerShowProps {
  isMyStory: boolean;
  totalViewer: number;
  caption?: string;
}

function ViewerShow({ isMyStory, totalViewer = 0, caption }: Readonly<ViewerShowProps>) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenCaption = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  if (!isMyStory) {
    return null;
  }

  return (
    <Flex flexDirection="column" alignItems="center" gap="16px">
      <If condition={!!caption}>
        <Text
          textAlign="center"
          color="$white"
          style={{ maxWidth: "85%" }}
          onClick={handleOpenCaption}
        >
          {caption && caption?.length > 100 && !isOpen
            ? `${caption?.substring(0, 100)}...`
            : caption}

          <Text as="span">
            {caption && caption?.length > 100 && !isOpen ? "See more" : ""}
          </Text>
        </Text>
      </If>
      <Flex alignItems="center" gap="8px" color="$white">
        <IoMdEye />
        <Text weight="$bold">{totalViewer}</Text>
      </Flex>
    </Flex>
  );
}

export default ViewerShow;
