import { VIDEO_FILES_EXTENSION } from "constants/app";
import { formatDistanceToNow } from "date-fns";
import useStoryStore from "features/explore/components/Story/storyStore";
import If from "global/ErrorHandlers/If";
import profileStore from "lib/stores/entities/profile";
import { useState } from "react";
import InstaStories from "stories-react";
import { IPostUser } from "types/responses/post";
import { IStory } from "types/responses/story";
import { getMediaExtension } from "utils/media";
import FooterStory from "./FooterStory";
import HeaderShow from "./HeaderShow";
import ModalViewer from "./ModalViewer";
import { Modal } from "./styled";
import ViewerShow from "./ViewerShow";

import "stories-react/dist/index.css";
import ModalDelete from "../ModalDelete";
import { useModalReportStore } from "../ModalReport/modalReportStore";
import ModalShare from "../ModalShare";

interface ModalShowStoryProps {
  visible: boolean;
  stories: Array<IStory>;
  user: IPostUser | null;
  onCancel: () => void;
  onStoryEnd: () => void;
}

// TODO: This component is too complex, consider refactoring

function ModalShowStory({
  visible,
  onCancel,
  stories = [],
  user,
  onStoryEnd,
}: Readonly<ModalShowStoryProps>) {
  const profile = profileStore((s) => s.user);
  const setReportItem = useModalReportStore((s) => s.setReportedItem);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentStory, setCurrentStory] = useState<IStory | null>(null);
  const viewStory = useStoryStore((s) => s.viewStory);
  const deleteStory = useStoryStore((s) => s.deleteStory);

  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [showViewer, setShowViewer] = useState(false);
  const [storyId, setStoryId] = useState("");
  const [totalView, setTotalView] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);

  const [sharedStory, setSharedStory] = useState<IStory | null>(null);

  const handleInitialStory = () => {
    const story = stories[0];

    if (story && story.viewed === "0") {
      viewStory(story.id);
    }
  };

  const handleStoryChange = (index: number) => {
    const story = stories[index];

    if (story) {
      setCurrentStory(story);
      if (story.viewed === "0") {
        viewStory(story.id);
      }
    } else {
      setCurrentStory(null);
    }
  };

  const handleAllStoryEnd = () => {
    onStoryEnd();
    setCurrentIndex(0);
  };

  const handleShowDelete = (id: string) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const handleCloseDelete = () => {
    setShowDelete(false);
    setDeleteId("");
  };

  const handleDeleteStory = async () => {
    const del = await deleteStory(deleteId);

    if (del) {
      handleCloseDelete();
      onCancel();
    }
  };

  const handleShowViewer = (index: number) => {
    const story = stories[index];

    if (story) {
      setShowViewer(true);
      setStoryId(story.id);
      setTotalView(Number(story.total_viewer));
      setTotalLikes(Number(story.total_like));
    }
  };

  const handleCloseViewer = () => {
    setShowViewer(false);
    setStoryId("");
    setTotalView(0);
    setTotalLikes(0);
  };

  const storyList = stories.map((story) => {
    const type = getMediaExtension(story.media);
    const url = story.media;
    const storyTime = formatDistanceToNow(new Date(story.created_at + 'Z'));
    const isMyStory = user?.id === profile?.id;

    const mediaType = VIDEO_FILES_EXTENSION.includes(type.toLowerCase())
      ? "video"
      : "image";

    return {
      url,
      type: mediaType,
      header: (
        <HeaderShow
          name={user?.name}
          photo={user?.photo}
          storyTime={storyTime}
          isMyStory={isMyStory}
          onDelete={() => handleShowDelete(story.id)}
          onReport={() => setReportItem(story.id, "story")}
          onShare={() => setSharedStory(story)}
        />
      ),
      seeMore: (
        <ViewerShow
          isMyStory={isMyStory}
          totalViewer={Number(story.total_viewer)}
          caption={story.caption}
        />
      ),
      onSeeMoreClick: handleShowViewer,
    };
  });

  return (
    <>
      <Modal open={visible} onCancel={onCancel} width={405} destroyOnClose>
        <If condition={storyList.length > 0 && !!user}>
          <InstaStories
            key={`stories-user-${user?.id}`}
            stories={storyList}
            currentIndex={currentIndex}
            onStoriesStart={handleInitialStory}
            onStoryChange={handleStoryChange}
            onAllStoriesEnd={handleAllStoryEnd}
            width={360}
            height={640}
            classNames={{
              storyContainer: "story-container",
            }}
          />
          <If condition={user?.id !== profile?.id}>
            {currentStory && <FooterStory story={currentStory} />}
          </If>
        </If>
      </Modal>
      <ModalViewer
        visible={showViewer}
        onCancel={handleCloseViewer}
        id={storyId}
        totalView={totalView}
        totalLikes={totalLikes}
        zIndex={1055}
      />
      <ModalDelete
        visible={showDelete}
        onCancel={handleCloseDelete}
        onDelete={handleDeleteStory}
      />
      <ModalShare
        open={!!sharedStory}
        title="Bagikan Story"
        link={`/cerita/${sharedStory?.id}`}
        content={sharedStory?.caption || ""}
        onCancel={() => setSharedStory(null)}
      />
    </>
  );
}

export default ModalShowStory;
