import { Avatar } from "antd";
import { Flex, Text } from "components/Base";
import If from "global/ErrorHandlers/If";
import { BsTrashFill } from "react-icons/bs";
import {
  PiWarningOctagonFill as Warning,
  PiShareNetworkFill as Share,
} from "react-icons/pi";

interface HeaderShowProps {
  name?: string;
  photo?: string;
  storyTime: string;
  isMyStory?: boolean;
  onDelete?: () => void;
  onReport?: () => void;
  onShare?: () => void;
}

function HeaderShow({
  name,
  photo,
  storyTime,
  isMyStory = false,
  onDelete,
  onReport = () => {},
  onShare = () => {},
}: Readonly<HeaderShowProps>) {
  const handleDelete = () => {
    if (!onDelete) return;

    onDelete();
  };

  return (
    <Flex w="100%" alignItems="center" justifyContent="space-between" px="16px">
      <Flex alignItems="center" flex="1" gap="16px">
        <Avatar src={photo} size={44} />
        <Flex flex="1" direction="column" color="$white">
          <Text fontSize="$lg" fontWeight="bold" italic={!name}>
            {name || "User Tanpa Nama"}
          </Text>
          <Text fontSize="$sm">{storyTime}</Text>
        </Flex>
      </Flex>
      <If condition={isMyStory}>
        <Flex justify="flex-end" cursor="pointer">
          <BsTrashFill
            size={20}
            color={"white"}
            cursor="pointer"
            onClick={handleDelete}
          />
        </Flex>
      </If>
      <If condition={!isMyStory}>
        <Flex justify="flex-end" gap="16px">
          <Share size={20} color={"white"} cursor="pointer" onClick={onShare} />
          <Warning
            size={20}
            color={"white"}
            cursor="pointer"
            onClick={onReport}
          />
        </Flex>
      </If>
    </Flex>
  );
}

export default HeaderShow;
